img {
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.slick-slide img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
}

.hero-container {
    height: 100vh;
    align-items: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: "Satisfy", system-ui;
    font-weight: 400;
    font-style: normal;
    overflow: hidden;
}


.hero-container>h1 {
    color: #fff;
    font-size: 100px;
    justify-content: center;
    text-align: center;
}

.hero-container>h3 {
    color: #000;
    font-size: 100px;
    justify-content: center;
    text-align: center;
}

.hero-container>p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    justify-content: center;
    text-align: center;
}

@media screen and (max-width: 960px) {
    .hero-container>h1 {
        font-size: 70px;
    }
}

@media screen and (max-width: 768px) {
    .hero-container>h1 {
        font-size: 50px;
    }
}