.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    /* Dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    /* On top of other content */
}

.popup-content {
    background: #fff;
    /* White background for the popup */
    padding: 2rem;
    /* Space around the content */
    border-radius: 10px;
    /* Rounded corners */
    width: 90%;
    /* Responsive width */
    max-width: 600px;
    /* Maximum width for larger screens */
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.3);
    /* Soft shadow for depth */
    position: relative;
    /* Needed for positioning the close icon */
    text-align: center;
    /* Center text within popup */
}

h2 {
    font-family: "Satisfy", system-ui;
    /* Consistent heading style */
    font-size: 28px;
    /* Heading font size */
    margin-bottom: 1rem;
    /* Space below the heading */
}

.popup-text {
    font-family: "Montserrat", sans-serif;
    /* Consistent font style */
    font-size: 15px;
    /* Font size for text */
    line-height: 1.5;
    /* Improved readability */
    color: #252e48;
    /* Text color */
}

.close-icon {
    position: absolute;
    /* Position the icon relative to popup content */
    top: 15px;
    /* Space from the top */
    right: 15px;
    /* Space from the right */
    cursor: pointer;
    /* Pointer cursor for clickable */
    color: #000;
    /* Close icon color */
    font-size: 20px;
    /* Icon size */
}

.fa-times {
    color: #000;
}

.close-icon:hover {
    /* Change color on hover */
    transform: scale(1.1);
    /* Slightly enlarge on hover */
}

/* Optional fade-in animation */
@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.popup-content {
    animation: fade-in 0.5s ease;
    /* Fade-in effect for the popup */
}