.footer-container {
    background-color: #000;
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 15px;
    padding: 15px;
    color: #fff;
}

.footer-subscription-heading {
    font-family: "Satisfy", system-ui;
    margin-bottom: 15px;
    font-size: 25px;
    color: #fff;
}

.footer-subscription-text {
    margin-bottom: 24px;
    font-size: 15px;
    font-family: "Montserrat", sans-serif;
    color: #fff;
}


.footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-link-wrapper {
    display: flex;
}

.footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px;
    text-align: center;
    width: 160px;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
}

.footer-link-items h2 {
    margin-bottom: 16px;
}

.footer-link-items>h2 {
    color: #fff;
}

.footer-link-items a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
    color: #e9e9e9;
    transition: 0.3s ease-out;
}

.footer-email-form h2 {
    margin-bottom: 2rem;
}

.footer-input::placeholder {
    color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
    color: #fff;
    font-size: 24px;
}

.social-media {
    max-width: 1000px;
    width: 100%;
}

.website-rights {
    color: #fff;
    margin-bottom: 16px;
}

@media screen and (max-width: 820px) {
    .footer-links {
        padding-top: 2rem;
    }

    .footer-input {
        width: 100%;
    }

    .btn {
        width: 100%;
    }

    .footer-link-wrapper {
        flex-direction: column;
    }

    .social-media-wrap {
        flex-direction: column;
    }
}

@media screen and (max-width: 768px) {}