form {
    background: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 2rem auto;
    font-family: "Montserrat", sans-serif;
}

label {
    font-size: 18px;
    font-weight: 600;
    color: #242424;
    margin-bottom: 0.5rem;
    display: block;
}

input[type="text"],
input[type="email"],
textarea {
    width: 100%;
    padding: 12px;
    margin-bottom: 1.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: vertical;
    font-size: 16px;
    transition: border-color 0.3s ease;
}

input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus {
    border-color: #5F9EA0;
    border-width: 3px;
    outline: none;
}

fieldset {
    border: none;
    margin-bottom: 1.5rem;
}

legend {
    font-size: 20px;
    font-weight: 600;
    color: #242424;
    margin-bottom: 0.5rem;
}

label input {
    margin-right: 0.5rem;
}

button {
    background-color: #5F9EA0;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #4B8B8B;
}

.ValidationError {
    color: red;
    font-size: 14px;
    margin-bottom: 1rem;
}