:root {
    --primary: #fff
}

.btn{
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.main--btn{
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
}

.outline--btn{
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.medium--btn{
    padding: 8px 20px;
    font-size: 20px;
}

.large-btn{
    padding: 12px 26px;
    font-size: 20px;
}

.medium--btn:hover, .large--btn:hover {
    background: #fff;
    color: #242424;
    transition: all 0.3s ease-out;
}


