.slider-container {
    width: 100%;
    height: 100vh;
    /* Full viewport height */
    position: absolute;
    /* Positioning for absolute children */
    overflow: hidden;
    /* Prevent overflow */
    z-index: -1;
}

.slider-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    /* Cover the entire area */
    background-position: center;
    /* Center the image */
    position: absolute;
    /* Overlay images */
    top: 0;
    left: 0;
    opacity: 0;
    /* Hide all images initially */
    transition: opacity 1s ease-in-out;
    /* Smooth transition effect */
}