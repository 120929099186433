* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.home, 
.venue, 
.restaurants, 
.barcelona, 
.subscribe,
.beauty,
.accomodation {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.home {
  background-size: cover;
  background-repeat: no-repeat;
}

.venue { 
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}


